<template>
  <div class="py-page">
    <myHead></myHead>
    <div class="banner">
      <img src="../assets/policy_bg.png" style="width: 100%;" alt="">
      <div class="fixed">
        <div class="title">ACUERDO DEL USUARIO</div>
      </div>
    </div>
    <div class="card cardpolicy">
      <div class="policycontent">
        <div>Bienvenido/a a CreditRey (en adelante, "la Aplicación"). Por favor, lea detenidamente y comprenda este Acuerdo del Usuario (en adelante, "el Acuerdo") antes de utilizar la Aplicación. El uso de la Aplicación indica su acuerdo con todos los términos de este Acuerdo. Si no está de acuerdo con algún contenido de este Acuerdo, por favor, deje de utilizar la Aplicación.</div>
        <div>&nbsp;</div>
        <div class="title1">1. Aceptación de los Términos</div>
        <div class="indent">1.1 Al descargar, instalar o utilizar la Aplicación, usted acepta quedar sujeto a los términos de este Acuerdo.</div>
        <div class="indent">1.2 Si está utilizando la Aplicación en representación de una entidad legal u otra organización, usted declara y garantiza que tiene la autoridad para obligar a esa entidad u organización a cumplir con este Acuerdo.</div>
        <div>&nbsp;</div>
        <div class="title1">2. Contenido del Servicio</div>
        <div class="indent">2.1 La Aplicación ofrece servicios de préstamos financieros a los usuarios.</div>
        <div class="indent">2.2 Usted comprende y acepta que algunas funciones de la Aplicación pueden requerir conexión a Internet, y será responsable de los costos asociados con la conexión.</div>
        <div>&nbsp;</div>
        <div class="title1">3. Cuenta de Usuario</div>
        <div class="indent">3.1 Al utilizar ciertas funciones o servicios de la Aplicación, es posible que necesite crear una cuenta de usuario. Usted debe asegurarse de que la información de registro proporcionada sea veraz, precisa, completa y esté actualizada.</div>
        <div class="indent">3.2 Usted es responsable de mantener la confidencialidad de su cuenta, así como de la seguridad de su contraseña.</div>
        <div class="indent">3.3 Si nota un uso no autorizado de su cuenta, debe notificar a la Aplicación de inmediato. No nos hacemos responsables de pérdidas causadas por el uso no autorizado de su cuenta antes de notificarnos.</div>
        <div>&nbsp;</div>
        <div class="title1">4. Normas de Conducta del Usuario</div>
        <div class="indent">4.1 Usted se compromete a no utilizar la Aplicación para realizar actividades ilegales o contrarias a las normas, incluyendo, pero no limitándose a:</div>
        <div class="indent">4.1.1 Crear, copiar, publicar, difundir o almacenar cualquier información ilegal, obscena, difamatoria, amenazante, calumniosa, o que viole la privacidad de terceros.</div>
        <div class="indent">4.1.2 Utilizar la Aplicación para realizar actividades fraudulentas, robo, o transacciones ilegales.</div>
        <div class="indent">4.1.3 Participar en acciones que puedan dañar la seguridad del sistema de la Aplicación, como ataques, intrusiones maliciosas, etc.</div>
        <div class="indent">4.1.4 Interferir de cualquier manera con el funcionamiento normal de la Aplicación o dañar los servidores y redes relacionados con la Aplicación.</div>
        <div class="indent">4.2 Usted acepta cumplir con todas las leyes y regulaciones aplicables, siendo totalmente responsable por sus acciones a través de la Aplicación.</div>
        <div>&nbsp;</div>
        <div class="title1">5. Política de Privacidad</div>
        <div class="indent">5.1 La Aplicación recopilará, utilizará y protegerá su información personal de acuerdo con su Política de Privacidad. Lea detenidamente la Política de Privacidad antes de utilizar la Aplicación.</div>
        <div class="indent">5.2 Usted acepta que la Aplicación recopile, utilice, transmita y revele su información personal de acuerdo con la Política de Privacidad.</div>
        <div>&nbsp;</div>
        <div class="title1">6. Propiedad Intelectual</div>
        <div class="indent">6.1 Todos los contenidos de la Aplicación, incluyendo, pero no limitándose a, texto, imágenes, iconos, audio, video, diseño de interfaz, son propiedad de la Aplicación.</div>
        <div class="indent">6.2 No se le permite usar, copiar, distribuir, mostrar, interpretar, vender o crear obras derivadas de ninguna manera sin el permiso expreso de la Aplicación.</div>
        <div>&nbsp;</div>
        <div class="title1">7. Descargo de Responsabilidad</div>
        <div class="indent">7.1 La Aplicación no garantiza que sus servicios o funciones estén libres de interrupciones, sean oportunos, seguros, precisos, completos o sin errores.</div>
        <div class="indent">7.2 Usted comprende y acepta que el uso de la Aplicación conlleva riesgos, incluyendo, pero no limitándose a, la divulgación de información, fallos del sistema, ataques de terceros, etc. Usted asume todos los riesgos asociados con el uso de la Aplicación.</div>
        <div>&nbsp;</div>
        <div class="title1">8. Cambios, Interrupción o Terminación del Servicio</div>
        <div class="indent">8.1 La Aplicación se reserva el derecho de cambiar, interrumpir o terminar parte o la totalidad de sus servicios previa notificación.</div>
        <div class="indent">8.2 En caso de que viole este Acuerdo, la Aplicación puede interrumpir o terminar sus servicios sin responsabilidad hacia usted.</div>
        <div>&nbsp;</div>
        <div class="title1">9. Otras Disposiciones</div>
        <div class="indent">9.1 Este Acuerdo puede modificarse debido a cambios en leyes y regulaciones, ajustes comerciales, entre otros motivos. La versión modificada se publicará en la Aplicación para su revisión.</div>
        <div class="indent">9.2 La interpretación, ejecución y resolución de disputas de este Acuerdo se rige por las leyes de Chile. En caso de disputa, las partes deben intentar resolverla de manera amistosa; de no llegar a un acuerdo, cualquiera de las partes puede presentar una demanda en el tribunal competente.</div>
        <div>&nbsp;</div>
      </div>
    </div>
    <myFoot :positive="positive" />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
export default {
  data() {
    return {
      positive: "absolute",
    };
  },

  methods: {},
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.py-page {
  position: relative;
  padding-bottom: 100px;
  .banner {
    position: relative;
    .fixed {
      width: max-content;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img {
        width: 60%;
      }
      .title {
        font-size: 40px;
        color: #fff;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
      }
    }
  }
  .cardpolicy {
    padding: 20px 0;
    background: #f6f6f6;
    .policycontent {
      width: 80%;
      margin: 0 auto;
      .title {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin: 40px 0;
      }
      .title1 {
        font-weight: bold;
        font-size: 22px;
        margin: 10px 0;
      }
      p,
      div {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a0404;
        line-height: 34px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
